<!--
 * @Description: 案场客服
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-01-14 20:39:25
-->
<template>
  <el-card class="box query">
    <el-form :model="query" ref="query" :inline="true" @submit.prevent="onSearch">
      <el-form-item label="关键字">
        <el-input
          v-model="query.q"
          placeholder="姓名、手机号、项目名称"
          clearable
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="处理报备">
        <el-select
          v-model="query.operation"
          placeholder
          style="width: 150px"
          clearable
        >
          <el-option label="允许" :value="0"></el-option>
          <el-option label="禁止" :value="1"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search" native-type="submit"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div>
      <el-button
        type="primary"
        size="small"
        icon="plus"
        v-if="is_can_edit"
        round
        @click="onEdit()"
        >新增</el-button
      >
    </div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border stripe class="fixed">
      <el-table-column
        label="项目"
        prop="estateName"
        min-width="150"
        fixed="left"
      ></el-table-column>
      <el-table-column label="姓名" prop="name" width="150"></el-table-column>
      <el-table-column label="手机" prop="mobile" width="150"></el-table-column>
      <el-table-column
        label="上班时间"
        prop="workingTime"
        width="200"
      ></el-table-column>
      <el-table-column label="隐藏客户号码" width="150">
        <template #default="scope">
          <span style="color: var(--el-color-success);" v-if="scope.row.isHideTel"
            >隐藏</span
          >
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column label="处理状态" width="120">
        <template #default="scope">
          <span
            style="color: var(--el-color-info)"
            v-if="scope.row.isOperation == 1"
            >禁止处理</span
          >
          <span style="color: var(--el-color-success)" v-else>允许处理</span>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        prop="creationTime"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            icon="edit"
            v-if="is_can_edit"
            >修改</el-button
          >
          <el-popconfirm
            width="220"
            v-if="is_can_del"
            title="您确定要删除此用户？"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button
                size="small"
                type="danger"
                link
                icon="delete"
                :loading="scope.row.deleting"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <edit-kefu :item="current_item" @success="onSuccess"></edit-kefu>
</template>

<script>
import EditKefu from "./edit_kefu.vue";
export default {
  components: {
    EditKefu,
  },
  data() {
    return {
      loading: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        sort: {
          order: "",
          prop: "",
        },
        q: "",
      },
      avatarUrl: require("@/assets/avatar.png"),
      tableData: {
        counts: 0,
      },
      current_item: null,
      is_can_edit: false,
      is_can_del: false,
    };
  },
  created() {
    this.is_can_edit = this.$power("admin/v1/assistant/edit");
    this.is_can_del = this.$power("admin/v1/assistant/del");
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("admin/v1/assistant", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.sort = {
          order: e.order,
          prop: e.prop,
        };
        this.onSearch();
      }
    },

    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 修改
     */
    onEdit(item) {
      this.current_item = Object.assign({}, item);
    },

    /**
     *
     */
    onSuccess() {
      this.current_item = null;
      this.loadData();
    },

    /**
     * 删除
     */
    onDelete(item) {
      item.deleting = true;
      this.$http.get(`admin/v1/assistant/del?id=${item.id}`).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg);
          if (this.query.pageIndex > 1) {
            if (this.tableData.data.length <= 1) {
              this.query.pageIndex--;
            }
          }
          this.loadData();
        }
      });
    },
  },
};
</script>

<style scoped></style>
